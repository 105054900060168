module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mcgeeclan","short_name":"McGee Clan LLC","start_url":"/","background_color":"#FFFFFF","theme_color":"#68008C","display":"standalone","icon":"/mnt/c/Github/tmcgee/mcgeeclan/src/images/LogoTransparent.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"03f5cd96c8f97c711d255df8424f17b9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
